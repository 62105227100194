.red {
  background: red;
}
.green {
  background: green;
}
.blue {
  background: blue;
}
.yellow {
  background: yellow;
}

/*#region general*/

@keyframes genericRotating {
  0% {
    transform: rotate(
        calc(0deg + calc(var(--offsetFactor) * var(--offsetDegree)))
      )
      translate(0px, 128px)
      rotate(calc(0deg - calc(var(--offsetFactor) * var(--offsetDegree))));
  }
  100% {
    transform: rotate(
        calc(360deg + calc(var(--offsetFactor) * var(--offsetDegree)))
      )
      translate(0px, 128px)
      rotate(calc(-360deg - calc(var(--offsetFactor) * var(--offsetDegree))));
  }
}

/*#endregion*/
/*#region indicators*/

.indicator-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.indicator1-root {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.indicator1-root div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: indicator1-keyframes 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.indicator1-root div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes indicator1-keyframes {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*#endregion*/
html,
body {
  margin: 0;
  padding: 0;
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  user-select: none;
  display: flex;
  flex-direction: column;
}
